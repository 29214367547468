import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Divider, Row, Col, Tooltip } from 'antd';
import { ListHeading } from 'src/components/ListHeading';
import DropdownSelect from 'src/lib/DropdownSelect';
import { Table } from 'antd';
import { options } from 'src/utils/tableConfig';
import usePagination from 'src/hooks/usePagination';
import { useQuery } from 'react-query';
import { getCompanies, getReports } from '../api';
import Loader from 'src/utils/Loader';
import { useStorage } from 'src/hooks/useStorage';
import utilsStorage from 'src/utils/storage';
import { API_URL } from 'src/config';
import moment from 'moment';
import axios from 'axios';
import { errorHandler } from 'src/utils/Error';
import { RBAC, ROLES } from 'src/lib/rbac';
import { useAuth } from 'src/lib/auth';
import SearchOptions from 'src/utils/SearchOptions';

const sections_options_city = [
  { value: 'vehicle', label: 'Po vozilu (sumirano)' },
  { value: 'driver', label: 'Po vozaču (sumirano)' },
  { value: 'vehicle-tour', label: 'Po vozilu' },
  { value: 'driver-tour', label: 'Po vozaču' },
  { value: 'waste-type', label: 'Po tipu otpada (preduzeće)' },
  { value: 'incident', label: 'Po količini nepoželjnog otpada' },
  { value: 'truck-times', label: 'Po vremenu zadržavanja kamiona na deponiji' },
  { value: 'tour', label: 'Po broju tura' },
  {
    value: 'jkp-municipal-waste',
    label: 'Po količini komunalnog otpada po preduzećima',
  },
  {
    value: 'jkp-incident',
    label: 'Po količini nepoželjnog otpada po preduzećima',
  },
  { value: 'daily-waste-type', label: 'Po danu i tipu otpada' },
];

const sections_options = [
  { value: 'vehicle', label: 'Po vozilu (sumirano)' },
  { value: 'driver', label: 'Po vozaču (sumirano)' },
  { value: 'vehicle-tour', label: 'Po vozilu' },
  { value: 'driver-tour', label: 'Po vozaču' },
  { value: 'waste-type', label: 'Po tipu otpada (preduzeće)' },
  { value: 'incident', label: 'Po količini nepoželjnog otpada' },
  { value: 'truck-times', label: 'Po vremenu zadržavanja kamiona na deponiji' },
  { value: 'tour', label: 'Po broju tura' },
  { value: 'daily-waste-type', label: 'Po danu i tipu otpada' },
];

function Reports() {
  const { storage, reportsTerm } = useStorage();
  const { user } = useAuth();
  const [filterData, setFilterData] = useState(reportsTerm);
  const [exportFilter, setExportFilter] = useState(reportsTerm);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const { page, setPage, term, setTerm, paginate, getPaginationProps } =
    usePagination();
  const listParams = { page, paginate, ...term };
  const reportFilterExist = storage.get('filterReports');

  const { data, isLoading } = useQuery(
    ['reports', listParams],
    () => getReports(listParams),
    {
      select: (res) => res.data,
      onSuccess: ({ current_page, last_page } = {}) =>
        current_page > last_page ? setPage(last_page) : 1,
    },
  );

  const { data: companyData, isLoading: isLoadingCompanies } = useQuery(
    'companies',
    () => getCompanies(),
    {
      select: (res) => res.data.companies,
    },
  );
  const companyOptions = companyData?.map((company) => ({
    label: company.company_name,
    value: company.company_id,
  }));
  const handleSelectChange = function (value, e) {
    setFilterData({ ...filterData, [e.name]: value.value });
  };

  const handleRangeChange = function (dates, dateStrings) {
    setFilterData({
      ...filterData,
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    });
  };

  const handleFilterFormSubmit = function (e) {
    e.preventDefault();
    setExportFilter(filterData);
    setPage(1);
    setTerm({ ...filterData });
    storage.set('filterReports', JSON.stringify({ ...filterData }));
  };
  const clearReportFilter = function () {
    if (!reportFilterExist) return;
    setPage(1);
    setTerm(reportsTerm);
    setFilterData(reportsTerm);
    setExportFilter(reportsTerm);
    storage.remove('filterReports');
  };

  const handleExportClick = function (type, loading) {
    const token = utilsStorage.getToken();
    if (token && type) {
      loading(true);
      axios({
        method: 'GET',
        url: `${API_URL}/admin/reports-export?report_type=${exportFilter.report_type}&start_date=${exportFilter.start_date}&end_date=${exportFilter.end_date}&export_type=${type}`,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const windowUrl = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          const selectedType = sections_options_city.filter(
            (report) => report.value === exportFilter.report_type,
          );
          const date = new Date();
          let hours = date.getHours();
          let minutes = date.getMinutes();
          let seconds = date.getSeconds();
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          day = ('0' + day).slice(-2);
          month = ('0' + month).slice(-2);
          hours = ('0' + hours).slice(-2);
          minutes = ('0' + minutes).slice(-2);
          seconds = ('0' + seconds).slice(-2);
          a.href = windowUrl;
          a.download = `Izveštaj ${selectedType[0].label.toLowerCase()} - ${day}.${month}.${year}. ${hours};${minutes},${seconds}.${type}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(windowUrl);
          loading(false);
        })
        .catch((err) => {
          errorHandler(err);
          loading(false);
        });
    }
  };

  useEffect(() => {
    if (reportFilterExist && !isLoading) {
      const existingFilter = JSON.parse(storage.get('filterReports'));
      setFilterData(existingFilter);
      setExportFilter(existingFilter);
      setTerm(existingFilter);
    }
  }, [isLoading]);

  const listHeadingClasses = {
    main: 'flex flex-col mb-2 xl:flex-row xl:justify-between xl:items-center',
    leftContainer:
      'flex mb-2 xl:mb-0 flex-col xl:flex-row xl:items-center w-full',
    right: 'flex justify-center xl:justify-end w-full xl:w-7/12  ',
    left: 'mt-3 xl:ml-4 xl:mt-0 w-full',
    title: 'text-2xl min-w-max',
  };

  if (isLoading || isLoadingCompanies) return <Loader />;

  if (!data) return <div className="text-lg">Nema podataka</div>;

  return (
    <>
      <ListHeading
        classNames={listHeadingClasses}
        title="Izveštaji"
        left={
          <form id="filterForm" className="" onSubmit={handleFilterFormSubmit}>
            <Row gutter={[8, 4]} className="w-full">
              <RBAC allowedRoles={[ROLES.ADMIN_CITY]}>
                <Col xl={20} sm={24} xs={24}>
                  <Tooltip title="Organizacija" className="w-full" color="#666">
                    <div className="dropdown-select-wrapper">
                      <DropdownSelect
                        options={companyOptions}
                        value={filterData.company_id}
                        onChange={handleSelectChange}
                        className="w-full"
                        placeholder="Organizacija"
                        name="company_id"
                        value={companyOptions.filter((option) => {
                          return option.value === filterData.company_id;
                        })}
                      />
                    </div>
                  </Tooltip>
                </Col>
              </RBAC>

              <Col xl={10} md={12} sm={24} xs={24}>
                <Tooltip
                  title="Vrsta izveštaja"
                  className="w-full"
                  color="#666">
                  <div className="dropdown-select-wrapper">
                    <DropdownSelect
                      name="report_type"
                      options={
                        user.role === 'admin_city'
                          ? sections_options_city
                          : sections_options
                      }
                      onChange={handleSelectChange}
                      placeholder="Vrsta izveštaja"
                      value={
                        user.role === 'admin_city'
                          ? sections_options_city.filter((option) => {
                              return option.value === filterData.report_type;
                            })
                          : sections_options.filter((option) => {
                              return option.value === filterData.report_type;
                            })
                      }
                    />
                  </div>
                </Tooltip>
              </Col>

              <Col xl={10} md={12} sm={24} xs={24}>
                <Tooltip
                  title="Početni i krajnji datum"
                  className="w-full"
                  color="#666">
                  <div>
                    {filterData.start_date !== '' ? (
                      <RangePicker
                        value={[
                          moment(filterData.start_date, 'YYYY-MM-DD'),
                          moment(filterData.end_date, 'YYYY-MM-DD'),
                        ]}
                        onChange={handleRangeChange}
                        className="w-full"
                      />
                    ) : (
                      <RangePicker
                        onChange={handleRangeChange}
                        className="w-full"
                      />
                    )}
                  </div>
                </Tooltip>
              </Col>

              <Col
                xl={4}
                md={24}
                sm={24}
                xs={24}
                className="flex xl:justify-start sm:justify-center xs:justify-center">
                <SearchOptions onClear={clearReportFilter} />
              </Col>

              <Col xl={0} sm={24} xs={24}>
                <Divider />
              </Col>
            </Row>
          </form>
        }
        right={
          <Row
            gutter={[8, 4]}
            className="w-full xl:mb-0 sm:mb-5 xs:mb-5"
            justify="end">
            <Col xl={6} sm={12} xs={12}>
              <Button
                loading={isExcelLoading}
                onClick={() => {
                  handleExportClick('xlsx', setIsExcelLoading);
                }}
                className="xs:w-full sm:w-full text-white bg-blue-500">
                Excel
              </Button>
            </Col>
            <Col xl={6} sm={12} xs={12}>
              <Button
                loading={isCsvLoading}
                onClick={() => {
                  handleExportClick('csv', setIsCsvLoading);
                }}
                className="xs:w-full sm:w-full text-white bg-blue-500">
                CSV
              </Button>
            </Col>
          </Row>
        }
      />
      <Table
        {...options}
        columns={data.header}
        dataSource={data.body}
        pagination={getPaginationProps({ total: data.total })}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={data.header.length}>
                  Ukupna Težina:{' '}
                  <span className="font-bold">{data.footer.total_weight}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              {data.footer.total_weight_burning && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={data.header.length}>
                    Ukupna težina za spaljivanje:{' '}
                    <span className="font-bold">
                      {data.footer.total_weight_burning}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
              {data.footer.total_weight_postponement && (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={data.header.length}>
                    Ukupna težina za odlaganje:{' '}
                    <span className="font-bold">
                      {data.footer.total_weight_postponement}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </>
  );
}

export default Reports;
