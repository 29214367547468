import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from 'src/components/Dashboard';

import {
  FaCarSide,
  FaUsers,
  FaWpforms,
  FaRoute,
  FaMapMarkerAlt,
  FaUserEdit,
  FaUserTie,
  FaRegEnvelope,
  FaRegListAlt,
  FaHistory,
} from 'react-icons/fa';

import EmployeesRoutes from 'src/features/employees/routes';
import TravelWarrantsRoutes from 'src/features/travel-warrants/routes';
import VehiclesRoutes from 'src/features/vehicles/routes';
import RoutePageRoutes from 'src/features/routes/routes';
import PointRoutes from 'src/features/points/routes';
import UsersRoutes from 'src/features/users/routes';
import NotificationsRoutes from 'src/features/notifications/routes';
import Map from 'src/features/map/routes';
import Alerts from 'src/features/alerts/routes';
import UserAccount from 'src/features/userAccount/routes';
import FuelDispensingsRoutes from 'src/features/fuel-dispensings/routes';
import LogsRoutes from 'src/features/logs/routes';
import ReportsRoutes from 'src/features/reports/routes';

import NotFound from 'src/components/NotFound';
import { ConfigProvider } from 'antd';
import sr from 'antd/lib/locale/sr_RS';
import { useRBAC, ROLES } from 'src/lib/rbac';
import { useAuth } from 'src/lib/auth';
import LegalEntitiesRoutes from 'src/features/legal-entities/routes';

const DashboardRoutes = () => {
  const { checkRoles } = useRBAC();
  const { user } = useAuth();
  let redirect = '/';
  const routes = [
    {
      title: 'Putni nalozi',
      url: '/travel-warrants',
      icon: <FaWpforms style={{ marginRight: '8px' }} />,
      Component: TravelWarrantsRoutes,
      roles: [ROLES.ADMIN_JKP, ROLES.CONTROLLER, ROLES.SERVICE],
    },
    {
      title: 'Vozila',
      url: '/vehicles',
      icon: <FaCarSide style={{ marginRight: '8px' }} />,
      Component: VehiclesRoutes,
      roles: [ROLES.ADMIN_JKP, ROLES.SERVICE],
    },
    {
      title: 'Zaposleni',
      url: '/employees',
      icon: <FaUsers style={{ marginRight: '8px' }} />,
      Component: EmployeesRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
    {
      title: 'Relacije',
      url: '/routes',
      icon: <FaRoute style={{ marginRight: '8px' }} />,
      Component: RoutePageRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
    {
      title: 'Punktovi',
      url: '/points',
      icon: <FaMapMarkerAlt style={{ marginRight: '8px' }} />,
      Component: PointRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
    {
      title: 'Korisnici',
      url: '/users',
      icon: <FaUserEdit style={{ marginRight: '8px' }} />,
      Component: UsersRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
    {
      title: 'Pravna Lica',
      url: '/legal_entities',
      icon: <FaUserTie style={{ marginRight: '8px' }} />,
      Component: LegalEntitiesRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
    {
      title: 'Obaveštenja',
      url: '/notifications',
      icon: <FaRegEnvelope style={{ marginRight: '8px' }} />,
      Component: NotificationsRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
    {
      title: 'Izveštaji',
      url: '/reports',
      icon: <FaRegListAlt style={{ marginRight: '8px' }} />,
      Component: ReportsRoutes,
      roles: [ROLES.ADMIN_JKP, ROLES.ADMIN_CITY],
    },
    // {
    //   title: 'Izdatnica',
    //   url: '/fuel-dispensings',
    //   icon: <FaRegListAlt style={{ marginRight: '8px' }}  />,
    //   Component: FuelDispensingsRoutes,
    //   roles: [ROLES.ADMIN_JKP, ROLES.SERVICE],
    // },
    {
      title: 'Istorija akcija',
      url: '/logs',
      icon: <FaHistory style={{ marginRight: '8px' }} />,
      Component: LogsRoutes,
      roles: [ROLES.ADMIN_JKP],
    },
  ].filter((item) => checkRoles({ allowedRoles: item.roles }));
  if (user.account_verified === 0) {
    redirect = { pathname: '/user-account', state: { isModalVisible: true } };
  } else if (user.role === 'admin_city') {
    redirect = '/reports';
  } else {
    redirect = '/travel-warrants';
  }
  return (
    <ConfigProvider locale={sr}>
      <Dashboard menuItems={routes}>
        <Switch>
          <Route exact path={`/`}>
            <Redirect to={redirect} />
          </Route>

          {routes.map(({ url, Component, roles }) => (
            <Route key={url} path={url}>
              <Component />
            </Route>
          ))}

          {/* <Route path={`/vehicles`}>
            <VehiclesRoutes />
          </Route>

          <Route path={`/employees`}>
            <EmployeesRoutes />
          </Route>

          <Route path={`/travel-warrants`}>
            <TravelWarrantsRoutes />
          </Route>

          <Route path={`/routes`}>
            <RoutePageRoutes />
          </Route>

          <Route path={`/points`}>
            <PointRoutes />
          </Route>

          <Route path={`/users`}>
            <UsersRoutes />
          </Route> */}

          <Route path={`/alerts`}>
            <Alerts />
          </Route>

          <Route path={`/map`}>
            <Map />
          </Route>

          <Route path={`/user-account`}>
            <UserAccount />
          </Route>

          <Route>
            {/* <Redirect to={`/`} /> */}
            <NotFound />
          </Route>
        </Switch>
      </Dashboard>
    </ConfigProvider>
  );
};

export default DashboardRoutes;
